<template>
  <div class="home">
   
    <v-container>
    <v-row style="height:300px;">
      <v-col></v-col>
    
    <v-col><div style="text-align:center; border-radius: 5px 5px 5px 5px;" >  <img src="../assets/duncan.jpeg" height="300px" style="box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);
-webkit-box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);
-moz-box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);"  /></div></v-col>
    <v-col></v-col>
    </v-row>
    <v-row style="height:50px;">

    </v-row>
    <v-row align="center">
      <h2>Experience</h2>
      <carousel-3d :controls-visible="true" :controls-prev-html="'&#10092; '" :controls-next-html="'&#10093;'" 
               :controls-width="30" :controls-height="60" :clickable="false" :width="600" :height="600">
  <slide :index="0" >
   <v-card style="height:100%; border-radius: 10px;
   background-image: url(../assets/19366.jpg);
  background-repeat: repeat-y;
  background-size: cover;  
 ">
    <v-card-title style="overflow-y:scroll" >
    
      <h5 style="word-wrap:normal">Chief Technology Officer</h5>
    </v-card-title>
    <v-card-subtitle>  <h6>October 2020-Current</h6></v-card-subtitle>
    <v-card-text class="home" style="height:100%;">
      <v-row>
        <v-col>
          Company:
        </v-col>
        <v-col>
          Statz Company / WorkStatz
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Responsibilites:
        </v-col>
        <v-col>
          <v-row>
            <v-col>Manage Development and Technical Teams</v-col>
          </v-row>
          <v-row>
            <v-col>Manage Technical Infrastructure</v-col>
          </v-row>
          <v-row>
            <v-col>Manage Data Infrastructure</v-col>
          </v-row>
          <v-row>
            <v-col>Manage System Security</v-col>
          </v-row>
          <v-row>
            <v-col>Manage Technical Budget</v-col>
          </v-row>
          <v-row>
            <v-col>Interact with Clients on Executive Level</v-col>
          </v-row>
          <v-row>
            <v-col>Design New Product Software and Technical Architecture</v-col>
          </v-row>
          <v-row>
            <v-col>Problem Solving</v-col>
          </v-row>
        </v-col>
      </v-row>
    
    </v-card-text>
   </v-card>
  </slide>
  <slide :index="1">
    <v-card style="height:100%;">
    <v-card-title>
      Senior Lecturer / Researcher
    </v-card-title>
    <v-card-subtitle>June 2019 – October 2020</v-card-subtitle>
    <v-card-text class="home" style="height:100%;">
      <v-row>
        <v-col>
          Company:
        </v-col>
        <v-col>
          Belgium Campus
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Responsibilites:
        </v-col>
        <v-col>
          <v-row>
            <v-col>Manage Software Development Lecturers</v-col>
          </v-row>
          <v-row>
            <v-col>Oversee Content Creation</v-col>
          </v-row>
          <v-row>
            <v-col>Lecture Students</v-col>
          </v-row>
          <v-row>
            <v-col>Create and Review Assessments and Examinations</v-col>
          </v-row>
          <v-row>
            <v-col>Develop Statistical Models</v-col>
          </v-row>
          <v-row>
            <v-col>Design and Develop Software Systems</v-col>
          </v-row>
          
        </v-col>
      </v-row>
    
    </v-card-text>
   </v-card>
   
  </slide>
  <slide :index="2">
    <v-card style="height:100%;">
    <v-card-title>
     C.E.O/ Lead Developer
    </v-card-title>
    <v-card-subtitle>June 2018 – October 2020</v-card-subtitle>
    <v-card-text class="home" style="height:100%;">
      <v-row>
        <v-col>
          Company:
        </v-col>
        <v-col>
          Night Viper Security Systems
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Responsibilites:
        </v-col>
        <v-col>
          <v-row>
            <v-col>Ensuring the continued growth and marketing of the business</v-col>
          </v-row>
          <v-row>
            <v-col>Developing new security systems</v-col>
          </v-row>
          <v-row>
            <v-col>Design and implementation of the Artificial Intelligent systems</v-col>
          </v-row>
          <v-row>
            <v-col>Design, development and maintenance of the database structure</v-col>
          </v-row>
          <v-row>
            <v-col>Managing the employees of the company</v-col>
          </v-row>
        
        </v-col>
      </v-row>
    
    </v-card-text>
   </v-card>
 
  </slide>
  <slide :index="3">
    <v-card style="height:100%;">
    <v-card-title>
     Specialist Developer / Release Manager
    </v-card-title>
    <v-card-subtitle>December 2015 – May 2018:</v-card-subtitle>
    <v-card-text class="home" style="height:100%;">
      <v-row>
        <v-col>
          Company:
        </v-col>
        <v-col>
          MediCharge
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Responsibilites:
        </v-col>
        <v-col>
          <v-row>
            <v-col>Design and implement new system specifications as defined by business requirements</v-col>
          </v-row>
          <v-row>
            <v-col>Optimise existing SQL server database</v-col>
          </v-row>
          <v-row>
            <v-col>Design and implementation of the Artificial Intelligent systems</v-col>
          </v-row>
          <v-row>
            <v-col>Oversee day to day functions of the junior developers</v-col>
          </v-row>
          <v-row>
            
            <v-col>Create upgrade packs from TFS and deploying to testing environments
</v-col>
          </v-row>
          <v-row>
            
            <v-col>Create formal upgrade packs from testing environments and deploying to live production environments
</v-col>
          </v-row>
        
        </v-col>
      </v-row>
    
    </v-card-text>
   </v-card>

  </slide>
  <slide :index="4">
    <v-card style="height:100%;">
    <v-card-title>
      Intermediate Developer
    </v-card-title>
    
   </v-card>
 
  </slide>
  <slide :index="4">
    <v-card style="height:100%;">
    <v-card-title>
      Junior Developer
    </v-card-title>
    
   </v-card>
 
  </slide>
</carousel-3d>
    </v-row>
    <v-row style="height:100px;"></v-row>
  </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
  name: 'Home',
  components: {
   Carousel3d,Slide
  }
}
</script>
<style scoped>
.home{
  background-image: url(../assets/19366.jpg);
  background-repeat: repeat-y;
  background-size: cover;
  
}
</style>
<style lang="scss">
.carousel-3d-container {
  
  .carousel-3d-slide {
   border:2px solid black;
    
    .title { font-size: 22px; }
  }
}

</style>
//
<template>
  <v-app>
    <v-app-bar
      app
      color="#000"
      dark
      rounded
  shaped
  src="https://picsum.photos/1920/1080?random"
      fade-img-on-scroll
      scroll-target="#scrolling-techniques-3"
    >
    <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(0,0,0,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>
      <div >
        <h4 style="color:white;display:inline; ">Welcome To </h4><h3 style="display:inline;">Duncan McIntyre's</h3> <h4 style="display:inline;">Digital CV</h4>
      </div>

     
    </v-app-bar>
    
    <v-main>
      <v-toolbar app>
       
      <v-toolbar-title>
        
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items >
        <v-btn 
          
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path">
        
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
    </v-toolbar>
      <router-view/>
     
    </v-main>
    <div style="position:fixed; bottom:0;"> <h6> <a href="https://www.freepik.com/free-vector/white-background-with-blue-tech-hexagon_4775334.htm#query=abstract%20technology&position=0&from_view=search&track=sph">Background Image by starline</a> on Freepik</h6></div>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    menuItems: [
          { title: 'About', path: '/about', icon: 'home' },
          { title: 'Experience', path: '/', icon: 'face' },
          { title: 'Skills', path: '/skills', icon: 'lock_open' },
          { title: 'Education', path: '/education', icon: 'lock_open' }
     ]
  }),
};
</script>
